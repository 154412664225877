.SI_name {
    padding: 16px;
}
.SI_content .SI_content-item {
    text-align: left;
    margin-left: 24px;
    font-size: 2rem;
    width: 1200px;
}

.SI_content .SI_content-item .label {
    width: 200px;
    text-align: right;
}

.SI_content .SI_content-item .input {
    width: 920px;
    margin-left:12px;
    padding-left: 24px;
    border: 0;
    border-bottom: 1px solid black;
}

.SI_total {
    margin-top: 24px;
    font-size: 2.4rem; 
}

.SI_total-content .SI_total-content-item {
    text-align: right;
    width: 100%;
    display: flex;
}

.SI_total-content .SI_total-content-item .label{
    font-weight: 600;
    text-align: right;
    width: 200px;
    padding-right: 20px;
}

.SI_total-content .SI_total-content-item .input{
    font-weight: 600;
    margin-left: 24px;
    width: 550px;
    border: 0;
}

.SI_footer {
    font-size: 2rem; 
    display: flex;
    margin-top: 24px;
}

.SI_footer-item .label{
    width: 200px;
    text-align: right;
    margin-right: 12px;
}

.SI_footer-item .input{
    border: 0;
    border-bottom: 1px solid black;
}

.input.hidden {
    display: none;
}

@media print {
    body {-webkit-print-color-adjust: exact;}

    @page {
      size: A4 landscape; /* set the page size to A4 in landscape orientation */
      margin: 1cm; /* set a 1cm margin on all sides */
      margin-header: 5000mm; /* set a 5mm header margin */
      margin-footer: 5mm; /* set a 5mm footer margin */
    }

    .no-printer {
        display: none  !important;
      }
    
    .d-flex.no-printer {
        display: none  !important;
      }

    .SI_name.no-printer {
        display: none  !important;
      }

     .input.no-printer {
        display: none  !important;
      }

      .input.hidden.printer {
        display: block !important;
      }

    .content.print {
    margin-top: 10px !important;
    }

  }
  