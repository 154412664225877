.App {
    text-align: 'unset';
}

.makeRoom {
    position: relative;
}
.makeRoom_container {
    text-align: left;
    margin-left: 20px;
    background-color: #d8dbdd
}

.makeRoom-back_home {
    position: absolute;
    top: -28px;
    left: 110px;
    font-size: 1.6rem;
    font-weight: 600;
    align-items: center;
    cursor: pointer;
}

.makeRoom_icon {
    color: blue;
    font-size: 1.5rem;
    text-align: center;
    align-items: center;
    margin-top: 5;
    margin-left: 5;
}

.makeRoom_children {
    border: 0.5px solid rgba(0,255,255,0.8);
    /* padding: 4px */
}

.makeRoom_label {
    font-size: 1.2rem;
    width: 16%;
    margin: 0;
    padding: 0;
    font-weight: 500;
    text-align: left
}

.makeRoom_textarea {
    width: 520px;
    font-size: 1.2rem;
}

.makeRoom_text {
    font-size: 1.2rem
}

.makeRoom_time {
    font-size: 1.4rem;
    padding: 4px;;
    width: 20px;
}

.form-control.makeRoom_time {
    font-size: 1.4rem;
    padding: 4px;;
    width: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .makeRoom_label {
        /* background-color: red; */
        font-size: 1rem;
    }

    .makeRoom_text {
        font-size: 1rem;
    }

    .makeRoom_textarea {
        /* background-color: red; */
        width: 200px;
        font-size: 1rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .makeRoom_label {
        /* background-color:aqua; */
        font-size: 1rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .makeRoom_label {
        /* background-color:green; */
        font-size: 1.1rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .makeRoom_label {
        /* background-color:yellow; */
        font-size: 1.25rem;
    }

    .makeRoom_text {
        font-size: 1.25rem;
    }

    .makeRoom_textarea {
        /* background-color:yellow; */
        width: 200px;
        font-size: 1.25rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1350px) {
    .makeRoomGuest_label {
        /* background-color:yellow; */
        font-size: 1.4rem;
    }

    .makeRoomGuest_text {
        font-size: 1.4rem;
    }

    .makeRoomGuest_textarea {
        /* background-color:yellow; */
        width: 200px;
        font-size: 1.4rem;
    }
}