.addService_detail {
  
    padding: 0 36px;
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .addService_detail {
        font-size: 1.2rem;
     
        padding: 0 96px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .addService_detail {
        font-size: 1.5rem;
        padding: 0 96px;
    }
}
