.table-show-detail .label-field {
    padding: 2px;
    margin: 0;
}

.table-show-detail .info-field {
    padding: 2px;
    margin: 0;
}

.table-show-detail td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.table-show-detail tr:nth-child(even) {
    background-color: #e8e7e7;
  }