.dropdown .dropdown-menu {
    background-color: rgb(207, 207, 207);
}

.dropdown-item {
    cursor: pointer;
    margin: 0;
    padding: 0;
}
/* .dropdown-item__input {
    width: 50%;
    border-radius: 10px;
    cursor: pointer;
} */

.dropdown-toggle {
    width: 100%;
}

.redcolor {
    color: red;
    border: none;
    padding: 0;
    margin: 0;
    align-items: flex-start;
}

.namesub__btn.dropdown-menu-input {
    width: 100%;
    margin: 2px;
    /* padding: 2px; */
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
}

.dropdown.select_branch {
    background-color: rgb(190, 236, 190);
    align-items: center;
    color: black;
    font-size: 1.4rem;
    /* border-radius: 5px; */
}

.dropdown.select_branch .btn-group {
    font-size: 1.4rem;
}

.dropdown.select_branch .dropdown-toggle-text {
    color: black;
    opacity: 1;
    font-size: 1.4rem;
    font-weight: 400;
    width: 100%;
    height: 100%;
    align-items: center;
    min-width: 120px;
    border: unset;
}
