.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    margin-top: 10px
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
    height: 20px;
    width: 20px;
    min-width: 50px;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
    height: 20px;
    width: 20px;
    min-width: 50px;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    font-size: 1.2rem;
}

.makeRoom-suggest-company-list {
    background-color: white;
    font-size: 1.2rem;
    position: absolute;
    left: 85px;
    width: 80%;
    text-align: left;
    z-index: 99;
    max-height: 200px;
    overflow: auto;
}

.makeRoom_company_detail {
    border: 1px solid; 
    background-color: #efefef; 
    width: 90%; 
    margin-left: 34px; 
    margin-top: 4px;
}

.makeRoom_children-company-clear_company_title {
    color: red ;
    font-size: 1.4rem ;
    margin-left: 100px
}

.makeRoom_children-company-clear_company_button {
    color: white; 
    width: 30px;
    padding: 2px;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
}
