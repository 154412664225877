.mail-content {
    font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.main-content-select-main {
    position: fixed;
    /* background: rgb(195, 195, 195); */
    overflow-x: hidden;
    z-index: 10;
    width: 100%;
}

.main-content-select-main_tab {
    font-size: 1.3rem;
    padding: 8px;
    background-color: white;
    border: 1px solid black !important;
    cursor: pointer;
}
.main-content-select-main_tab.select {
    background-color: yellow;
}
.main-content-lang {
    position: fixed;
    /* width: 100%; */
    margin-top: 33px;
    z-index: 10;
    background: rgb(195, 195, 195);
}

.cursorPointer {
    cursor: pointer !important;
}