.list-room {
    position: sticky;
    left: 0;
    z-index: 10;
}

.list-room_title {
    height: 40px;
    /* background-color: #006400; */
    background-color: #04436C !important;
    color:white;
    margin: auto;
    font-size: 1.3rem;
    font-weight: bold;
    padding-top:10px
}

.column-moja {
    background-color: #04436C !important;
}

.column-az {
    background-color: #006400 !important;
}

.day-moja {
    background-color:  #94DCFF
}

.day-az {
    background: rgba(144 238 144 / 35%);
}

.list-room_type {
    /* height:200px; */
    width: 120px;
    border: 1px solid rgb(231, 228, 228);
    font-weight: 600;
    font-size: 1.4rem;
    overflow-x:inherit;
    /* background-color: #006400; */
    background-color: #04436C !important;
    color:white;
    text-align: center;
    align-items: center;
}

.list-room_list-room {
    /* padding: 12px; */
    font-weight: 600;
    width: 80px;
    height: 50px;
    font-size: 1.4rem;
    border: 1px solid white;
    /* rgb(231, 228, 228); */
    /* background-color: #006400; */
    background-color: #04436C !important;
    color:white;
    text-align: left;
    padding-left: 8px;
}

.rsv-container {
    width: max-content;
}

.rsv {
    font-size: 1.5rem;
}

/* .rsv-ca {
    position: fixed;
} */
.rsv-day {
    width: 80px;
    border: 1px solid white; 
    /* rgb(252, 252, 252); */
    /* background-color: #006400; */
    background-color: #04436C !important;
    color: white;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 7px;
}

.rsv-room {
    width: 80px;
    /* background: rgba(144 238 144 / 35%); */
    /* z-index: 15; */
    background-color:  #94DCFF;
    height: 50px;
    font-size: 1.3rem;
    text-align: left;
    padding: 8px;
    border: 1px solid white;
    /* rgb(255, 255, 255); */
    cursor: pointer;
    position: relative;
}

.rsv-room_half {
    width: 40px;
    /* background: rgba(144 238 144 / 35%); */
    background-color:  #94DCFF;
    height: 50px;
    font-size: 1.3rem;
    text-align: left;
    padding: 8px;
    border: 1px solid rgb(231, 228, 228);
    cursor: pointer;
    position: relative;
}

.rsv-room:hover .rsv-room_tip {
 display: block;
}

.rsv-room:hover .rsv-room_tip-left {
    display: block;
   }

.rsv-room_tip {
    position: absolute;
    inset: -265.776px auto auto 31.0114px;
    display: none;
    height: auto;
    width: 300px;
    /* top:20px;
    right: -12px; */
    background: bisque;
    z-index: 999;
}

.rsv-room_tip-left {
    position: absolute;
    inset: 10.224px auto auto -264.9886px; 
    display: none;
    height: auto;
    width: 300px;
    display: block;
    /* top:20px;
    right: -12px; */
    background: bisque;
    z-index: 999;
}

.tablet .info-field {
    width: 90%;
}   

.rsv-room.active {
    color:white;
    font-weight: 600;
    border: 1px solid white;
    cursor: pointer;
}

.rsv-room.active .table {
    font-size: 1.2rem;
    font-weight: 600;
}

.rsv-room:hover {
    border: 1px solid red;
}

.rsv-room.active:hover {
    border: 2px solid red;
}

.w-80 {
    width: 70%;
}

.show-small-icon_left1 {
    font-size: 1rem !important; 
    position: absolute;
    top: 2px;
    left: 2px;
    color: white;
}

.show-small-icon_left2 {
    font-size: 1.1rem !important; 
    position: absolute;
    top: 2px;
    left: 15px;
    color: white;
}

.show-small-icon_left3 {
    font-size: 1.1rem !important; 
    position: absolute;
    top: 2px;
    left: 30px;
    color: white;
}

.show-small-icon_left4 {
    font-size: 1.1rem !important; 
    position: absolute;
    top: 2px;
    left: 45px;
    color: white;
}


.show-small-icon_right1 {
    font-size: 1.1rem !important; 
    position: absolute;
    top: 2px;
    right: 0px;
    color: white;
}