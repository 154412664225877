.HI_content {
    font-size: 1.2rem;
}

.HI_header {
    padding-left: 12px;
}

.HI_content-header-item {
    padding: 0 24px;
}

.HI_content-header-item .label{
    width: 80px;
    text-align: left;
}

.HI_content-header-item input{
    width: 400px;
    text-align: left;
    border:0;
    padding-left: 12px;
    border-bottom: 1px solid black;
}

.HI_content-content_table {
    margin: auto;
    width: 95%;
}

.text-center {
    text-align: center;
}

.extend-btn {
    opacity: 0.5;
}

.extend-btn:hover {
     opacity: 1;
}

.table .table-bordered {
    margin: auto;
    width: 95%;
}