.container-fluid {
    font-size: 1.4rem;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.ng-binding {
    font-weight: 600;
}

.tbl-header{
    width: 100%;
}

.tbl-header td.col1, 
.tbl-header td.col3 {
    width: 25%;
    vertical-align: top;
}

.tbl-header td.col2 {
    width: 50%;
    text-align: center;
    vertical-align: bottom;
}

.bk-title {
    color: #5f497a !important;
    font-size: 2.2rem
}

.bk-title > span:last-child {
    font-size: 18px;
    color: #5f497a !important;
    font-weight: 600;
}

.rbk-detail {
    width: 100%;
    margin-top: 0px;
    border-collapse: collapse;
}

.rbk-detail-head td {
    height: 20px;
    line-height: 20px;
    background-color: #5f497a !important;
    color: white;
    text-align: center;
    font-weight: bold;
}

.rbk-detail-body td.col1 {
    width: 50px;
    padding-top: 1px;
}

.rbk-detail-body .tr-title td {
    background-color: #e5dfec !important;
    color: #8f497a !important;
}

.rbk-detail-body td {
    height: 25px;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.rbk-detail td {
    border: 1px solid #8064a2;
}

.white-color {
    color: #fff !important;
}

.red-color {
    color: red !important;
}

.rbk-footer div.rh-left, .rbk-footer div.rh-right {
    height: 40px;
    text-align: center;
    vertical-align: top;
    padding-top: 4px;
}

.rbk-footer div.rdt-left {
    padding-top: 10px;
}

.rbk-footer div.rmap-right, .rbk-footer div.rdt-left {
    padding-left: 10px;
    padding-right: 10px;
}

.rbk-footer div.rdt-left, .rbk-footer div.rmap-right {
    height: 350px;
}

.rbk-footer .color-white {
    color: #FFFFFF !important;
}

.rbk-footer tbody:nth-child(2) td {
    height: 40px;
    background-color: #5f497a !important;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
}

.rn-left, .rn-right {
    height: 25px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
}

.rf-left, .rf-right {
    min-height: 25px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
}

@media print {
    body {-webkit-print-color-adjust: exact;}

    @page {
      size: A4 portrait; /* set the page size to A4 in landscape orientation A4 */
      margin: 1cm; ; /* set a 1cm margin on all sides */
      margin-header: 100mm; ; /* 5000mm; /* set a 5mm header margin */
      margin-footer: 5mm; ; /* set a 5mm footer margin */

      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
      width:400;
      height:400;
    }
    
    .d-flex.no-printer {
        display: none  !important;
      }

    .content.print {
    margin-top: 10px !important;
    }


    
    /* additional print styles */
    /* ... */
  }
  