.right_click_menu {
    width: 200px;
    background-color: aqua;
    list-style-type: none;
    position: absolute;
    cursor: pointer;
    z-index: 999;
    left: -100
}

.right_click_menu-list {
    /* list-style-type: none; */
    padding : 0 0 0 16px ;
}

.right_click_menu-item:hover {
    background-color: white;
}

.bg_white {
    /* background-color: rgb(255, 239, 239) !important;  */
    border: unset;
    padding: unset;
}

.__react_component_tooltip {
    background-color: white !important; 
    padding: 4px !important; 
    margin: 0 !important; 
    opacity: 1 !important; 
    z-index: 99 !important; 
}
