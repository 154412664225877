.header-feature {
    font-size: 1.3rem;
    z-index: 11;
    text-align: left;
    /* margin-left: 200px; */
    padding-left: 200px;
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    height: 35px;
}

.header-feature_title {
    padding-left: 250px;
    font-weight: 600;
    font-size: 2.5rem;
}

/* .header-arrow {
    background-color: #04436C;
    color: white;
    font-size: 1.7rem ; 
    text-align: center ; 
    min-width:  24px ;
    font-weight:  700 ;
} */

.header-arrow {
    /* background-color: #006400; */
    background-color: #04436C !important;
    color: white;
    font-size: 1.7rem ; 
    text-align: center ; 
    min-width:  24px ;
    font-weight:  700 ;
}