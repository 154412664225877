@media print {
    .glist .no-border {
        border: unset;
    }
}
.set-color {

}
.set-color_tab {
    display: none;
}

.set-color:hover .set-color_tab {
    display: block;
}

.glist {

}

.glist .content {
    margin-bottom: 60px;
}

.glist .glist-title{
    margin-left: 250px;
    /* background-color: #482979;
    color: white; */
}

.glist-content {
    width: 95%;
}

/* .glist .glist-main table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .glist .glist-main td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .glist .glist-main tr:nth-child(even) {
    background-color: #dddddd;
  } */