.pmrq_function {
    text-align: left !important;
    margin-left: 12px;
    margin-bottom: 4px;
}

.pmrq-hidden-when-have-not-print {
    display: none;
}

.pmrq_function-select {
    font-size: 1.4rem;
    min-width: 80px;
    text-align: center;
}

.pmrq_function-checkbox {
    font-size: 1.3rem;
}

.pmrq_table_row {
    border: 1px solid black;
    font-size: 1.3rem;
    /* background-color: #9966FF; */
    background-color: #9966ff35;

}

.pmrq-footer {
    text-align: left;
    margin-left: 24px;
}

.pmrq-footer_text {
    font-size: 1.3rem;
}

.pmrq-footer_sign {
    margin-bottom: 100px;
    font-size: 1.4rem;
    font-weight: 600;
    padding-left: 75%;
    width: 400px;
    align-items: center;
}

.pmrq-footer_sign .react-datepicker-wrapper {
    width: 100px;
    
}

.pmrq-footer_sign-text {
    text-align: right;
}

.pmrq-footer_sign-input_date {
    font-size: 1.4rem;
}

.pmrq-footer_sign-input {
    width: 300px;
    font-size: 1.4rem;
    text-align: center;
}

.pmrq-footer_bank_account {
    background-color: aqua;
    font-size: 1.3rem;
    padding: 4px;
    border: 1px solid black;
    height: 80px;
}

.pmrq-footer_bank_account .bank_account {
    cursor: pointer;
}

.font_weight_600 {
    font-weight: 600;
}
