.guest-name_short-time {
    position:  relative;
}
.guest-name_short-time_count {
    margin: auto;
    padding: 0 25px;
}

.guest-name_short-time_list {
    display: none;
    position: absolute;
    right: -40px;
    padding: 0 10px;
    right: 6px;
    top: 25px;
    z-index: 10;
}

.guest-name_short-time_count:hover .guest-name_short-time_list{
    display: block;
}

.guest-name_short-time:hover .guest-name_short-time_list{
    display: block;
}

.man-show-data-list {
    position: relative;
    font-size: 1.5rem;
    padding: 8px;
    /* margin-left: 60%; */
    list-style: none;
    border-radius: 10px;
    display: block;
    margin-left: 120px;
}

.man-show-data-list .man-show-data-list_item {
    border: 1px solid black;
    margin: 0;
    padding: 4px;
    min-width: 50px;
    /* background-color: #999; */
    min-height: 32px;
}
