.react-datepicker-ignore-onclickoutside {
    /* width: 100px !important; */
}

.react-datepicker__input-container input {
    width: 100px !important;
}

.react-datepicker__month-container {
    font-size: 1.3rem !important;
}

.makeRoom-checkInOut .react-datepicker__navigation.react-datepicker__navigation--next {
    min-width: 20px !important;
    height: 20px !important;
}

.makeRoom-checkInOut .react-datepicker__navigation.react-datepicker__navigation--previous {
    min-width: 20px !important;
    height: 20px !important;
}

.makeRoom_time {
    font-size: 1.4rem;
    padding: 4px;;
    width: 20px;
}

.form-control.makeRoom_time {
    font-size: 1.4rem;
    padding: 4px;;
    width: 20px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1350px) {

}